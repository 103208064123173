.home_peges {
    display: flex;
    margin: 5rem;
}

.home_peges_img {
    border-radius: 5%;
    height: 50%;
    width: 800px;
}

.home_peges_content {
    margin: 0 2rem;
    padding: 1rem;
}

.home_peges_content > h2 {
    color: var(--accent-color);
    font-size: 2rem;
    font-family: "Roboto", sans-serif;
    margin: 2rem 0;
}

.home_peges_content > h5 {
    color: #011126;
    font-size: 1.4rem;
    text-align: left;
    margin: 1rem 0;
    font-family: "Roboto", sans-serif;
}

.home_peges_content > h6 {
    color: #011126;
    font-size: 1.1rem;
    font-family: "Roboto", sans-serif;
}

.home_peges_content > p {
    color: var(--text-color);
    font-size: 1rem;
    margin: 1rem 0;
}

.home_peges_content > button {
    color: var(--accent-color);
    text-align: right;
    font-size: 1rem;
    margin-top: 2rem;
    border: none;
    cursor: pointer;
    background-color: white;
    transition: background-color 0.3s;
    position: relative;
    padding: 0.5rem 1rem;
}

.home_peges_content >button::after {
    content: "";
    height: 2px;
    width: 0;
    background-color: var(--accent-color);
    display: block;
    transition: width 0.5s ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
}

.home_peges_content >button:hover::after {
    width: 100%;
}

@media (max-width: 1000px) {
    .home_peges {
        margin: 2rem;
        flex-direction: column;
    }

    .home_peges_img {
        width: 100%;
        height: auto;
    }

    .home_peges_content > h2 {
        font-size: 1.5rem;
    }

    .home_peges_content > h5 {
        font-size: 1.2rem;
    }

    .home_peges_content > h6 {
        font-size: 1rem;
    }

    .home_peges_content > p {
        font-size: 0.9rem;
    }

    .home_peges_content > button {
        font-size: 0.9rem;
        padding: 0.5rem 0.8rem;
        margin-top: 1.5rem;
        text-align: center;
    }
}

@media (max-width: 600px) {
    .home_peges {
        margin: 1rem;
    }

    .home_peges_content > h2 {
        font-size: 1.2rem;
    }

    .home_peges_content > h5 {
        font-size: 1rem;
    }

    .home_peges_content > h6 {
        font-size: 0.9rem;
    }

    .home_peges_content > p {
        font-size: 0.8rem;
    }

    .home_peges_content > button {
        font-size: 0.8rem;
        padding: 0.5rem;
        margin-top: 1rem;
        text-align: center;
    }
}

.in_view_img {
    opacity: 1;
    transform: translateX(-20px);
    animation: fadeInLeft 2s forwards;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.in_view_content {
    opacity: 1;
    animation: fadeInRight 2s forwards;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}
