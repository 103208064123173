.language-switcher {
    position: relative;
    display: inline-block;
    margin-left: 2rem;
}

.dropdown-toggle {
    background: no-repeat center center;
    background-size: cover;
    border: none;
    width: 35px; 
    height: 25px; 
    cursor: pointer;
    border-radius: 4px;
    background-color: #fff; 
    transition: background-color 0.3s;
}

.dropdown-toggle:hover {
    background-color: #f0f0f0; 
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.429);
    border: 1px solid #ddd;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 2s ease, opacity 0.3s ease;
}

.dropdown-menu.show {
    max-height: 200px;
    opacity: 1;
}

.dropdown-item {
    width: 40px; 
    height: auto;
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.3s;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
}

@media (max-width:1180px) {
    
    .language-switcher{
        margin-left: 0rem;
        margin-top: 1rem;
    }
}