.service-img {
    width: 100%;
    height: 35vh;
    position: relative;
    top: 0;
    left: 0; 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../assets/images/services.jpg);
    z-index: -1;
}
.service-img::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 0;
}
.section-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
}

.section-content.open {
    max-height: 500px;
    opacity: 1;
}
.section-content>p{
    color: var(--content-color);
}
.service {
    margin: 8rem;

}

.service_img1 {
    height: 20rem;
    object-fit: cover;
    width: 25rem;
    background-repeat: no-repeat;
}

.service>h1 {
    font-size: 1.5rem;
    margin: 2rem 0;
}

.service>h2 {
    font-size: 1.8rem;
    margin: 2rem 0;
}

.service>p {
    color: var(--content-color);
    font-size: 1.2rem;
    margin-top: 1rem;
}

.services-text {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    color: var(--secondary-color);
    text-align: left;
    z-index: 1;
}

.services-text h1 {
    font-size: 3rem;
    margin: 0;
}

.services-text h6 {
    font-size: 1rem;
    margin: 1rem 0;
}

.services-text span {
    color: var(--accent-color);
}

.service>h3 {
    font-size: 2rem;
    color: var(--title-color);
    cursor: pointer;
    margin: 1rem 0;
}

.service>h3:hover {
    color: var(--text-color);
}

.service_1>p {
    color: var(--content-color);
    font-size: 1.2rem;
    margin-top: 1rem;
}

.service_1>h3 {
    margin: 1rem 0;
    font-size: 1.4rem;
    color: var(--title-color);
}

.service_2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.service_2_content>h1 {
    font-size: 2rem;
    margin: 1rem 0;
    cursor: pointer;
    color: var(--title-color);

}

.service_2_content>h1:hover {
    color: var(--text-color);
}
.service_2_contant>h3 {
    margin: 0.5rem 0;
    font-size: 1.5rem;
    color: var(--title-color);
}



.service_2_contant>p {
    color: var(--content-color);
    font-size: 1rem;
}

.service_2>img {
    height: 25rem;
    width: 35rem;
    border-radius: 10%;
}

@media (max-width:1180px) {
    .service {
        margin-top: 15rem;
    }
    .service_2 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .service_2>img {
        height: 20rem;
        width: 30rem;
    }
}

@media (max-width: 600px) {
    .service {
        display: flex;
        flex-wrap: wrap;
        align-items: left;
    }

    .service_2_contant {
        font-size: 1.5rem;
    }
    .service {
        margin: 15rem 2rem;
    }

    .service_2_content>h1 {
        font-size: 2rem;
        margin: 1rem 0;
        cursor: pointer;
    }

    .service_2_contant>h3 {
        font-size: 1.5rem;
    }

    .service>h3 {
        font-size: 1.5rem
    }

    .service>h3 {
        font-size: 1.5rem;
    }

    .service_2>img {
        height: 200px;
        width: 300px;
    }
}

@media (max-width: 500px) {
    .services-text h1{
        font-size: 2rem;
    }
}