.slider-container {
  width: 100%;
  z-index: 1000;
}

.slick-slide {
  position: relative;
}

.slick-slide img {
  width: 100%;
  height: 40rem;
  object-fit: cover;
  filter: brightness(0.6);
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

.overlay {
   position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1000;
}

.center-text {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.slick-prev,
.slick-next {
  display: none !important;
}

.slick-dots li button {
  display: none !important;
}

.overlay h3,
.overlay p {
  opacity: 0;
  animation: fadeInUp 1s ease-in-out forwards;
}

.overlay h3 {
  width: 60rem;
  font-size: 4rem;
  animation-delay: 0.5s;
}
.overlay h3>span{
  color: var(--accent-color);
}

.overlay p {
  margin: 10px 0 0 0;
  font-size: 2.9rem;
  animation-delay: 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@media (max-width:900px) {
  .overlay {
    position: absolute;
    top: 40%;
    left: 5rem;
    transform: translateX(-10%);
    color: white;
  }

  .overlay h3 {
    width: auto;
    font-size: 3rem;
    animation-delay: 0.5s;
  }

  .overlay p {
    font-size: 1.4rem;
    width: auto;
  }

  .center-text {
    left: 10%;
  }
}

@media (max-width:500px) {
  .overlay h3 {
    font-size: 2.5rem;
  }
}