.info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 2.2rem;
  width: 100%;
  background-color: var(--bg-color);
  z-index: 1001;
}
.contact-data {
  display: flex;
}
.time {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-top: 0.2rem;
  color: var(--text-color);
}
.time > span {
  font-size: 1.2rem;
  margin-top: 0.2rem;
  margin-right: 0.5rem;
  color: var(--accent-color);
}
.mail {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-top: 0.2rem;
  margin-left: 2rem;
  color: var(--text-color);
}
.mail > span {
  font-size: 1.2rem;
  margin-top: 0.2rem;
  color: var(--accent-color);
  margin-right: 0.5rem;
}
.phone-number {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-top: 0.2rem;
  margin-left: 2rem;
  color: var(--text-color);
}
.phone-number > span {
  font-size: 1.2rem;
  margin-top: 0.2rem;
  color: var(--accent-color);
  margin-right: 0.5rem;
  margin-left: 0.5rem;
} 

.map-text {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-top: 0.2rem;
  color: var(--text-color);
}
.map-text > span {
  font-size: 1.2rem;
  margin-top: 0.2rem;
  margin-right: 0.5rem;
  color: var(--accent-color);
}
.logo > img {
  width: 8.75rem;
  height: 4.8rem;
  font-weight: 600;
  margin: 1.5rem 0;
  cursor: pointer;
}
.navbar {
  width: 100%;
  height: 5rem;
  padding: 1rem 2%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .3s;
  z-index: 1002;
}
.nav-links {
  display: flex;
    align-items: center;
    justify-content: flex-start; 
}
.navbar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease-in-out;
}
@keyframes slideDown {
  from {
    top: -5rem;
  }
  to {
    top: 0;
  }
}
.nav-links a {
  margin-left: 2.5rem;
  text-decoration: none;
  color: var(--secondary-color);
  font-size: 1.4rem;
  cursor: pointer;
}
.nav-links a:hover {
  color: var(--accent-color);
}
.nav-links a.active {
  text-decoration: none;
  color: var(--accent-color);
}
.navbar > .new-toggle {
  display: none;
}
@media (max-width:1180px) {
  .info {
    display: none;
  }
  .logo > img {
    width: 6.25rem;
    height: 3.125rem;
  }
  .nav-links a {
    margin-top: 5rem;
  }
  .navbar > .nav-links {
    position: absolute;
    top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--primary-color);
    box-shadow: 0.013rem 0.013rem 0.013rem 0.013rem rgb(255, 255, 255);
    left: 0;
    width: 100%;
    height: auto;
    transform: translateX(-100%);
    transition: all .45s;
    text-align: center;
    padding: 9% 0;
    z-index: 1003;
  }
  .navbar > .nav-links.open {
    transform: translateX(0);
  }
  .nav-links a{
    margin-left: 0;
  }
  .no-scroll {
    overflow: hidden;
}
  .navbar > .new-toggle {
    display: flex;
    width: 3.125rem;
    height: 3.125rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .new-toggle > .bar {
    position: relative;
    width: 2rem;
    height: 0.125rem;
    background-color: white;
    transition: all .45s ease-in-out;
  }
  .new-toggle > .bar::before,
  .new-toggle > .bar::after {
    content: '';
    position: absolute;
    height: 0.125rem;
    background: white;
    border-radius: 0.125rem;
    transition: all .45s ease-in-out;
  }
  .new-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }
  .new-toggle > .bar::after {
    width: 32px;
    transform: translateY(8px);
  }
  .new-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }
  .new-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }
  .new-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}